import c from 'classnames';
import _ from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { random, resetRandomSeed, squarify } from './lib';
import styles from './TransitionTiles.module.css';

interface IProps {
	/** [0,1] */
	dayAmount: number;
	/** In seconds */
	twilightDuration: number;
}

/**
 * Renders day tiles on top of night tiles.
 * Day tiles fade in/out as twilight progresses.
 */
export const TransitionTiles: React.FC<IProps> = ({ dayAmount, twilightDuration }) => {
	const rootRef = useRef<HTMLDivElement>(null);

	// enable transitions after the first render,
	// so that the initial load is instant when in twilight
	const [transition, setTransition] = useState(false);
	useEffect(() => { setTransition(true); }, []);

	const [rowsCols, setRowsCols] = useState(() => squarify());
	useEffect(
		() => {
			const f = () => {
				setRowsCols(squarify());
			};
			window.addEventListener('resize', f);

			return () => {
				window.removeEventListener('resize', f);
			};
		},
		[]
	);

	const tileCount = rowsCols[0] * rowsCols[1];
	/**
	 * An array of booleans, representing which tiles are visible.
	 * `dayAmount` of them will be left visible
	 */
	const tileVisibilities = useMemo(
		() => {
			const state: boolean[] = new Array(tileCount).fill(true);

			resetRandomSeed();

			for (let n = 0; n < (1 - dayAmount) * tileCount; n++) {
				let done = false;
				while (!done) {
					const iToTest = Math.floor(random() * tileCount);
					const hasToggled = !state[iToTest];
					if (!hasToggled) {
						state[iToTest] = false;
						done = true;
					}
				}
			}

			return state;
		},
		[dayAmount, tileCount]
	);

	return (
		<div
			className={c(styles.root, transition && styles.root__transition)}
			ref={rootRef}
			style={{
				gridTemplateColumns: `repeat(${rowsCols[1]}, 1fr)`,
				gridTemplateRows: `repeat(${rowsCols[0]}, 1fr)`
			}}
		>
			{_.range(0, rowsCols[0] * rowsCols[1]).map(i => {
				return (
					<div
						className={styles.tile}
						key={i}
						style={{
							opacity: tileVisibilities[i] ? 1 : 0,
							transitionDuration: `${twilightDuration / tileCount}s`
						}}
					/>
				);
			})}
		</div>
	);
};
