import c from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { computeDaylightAmount, fetchTimes, ITimes } from './lib';
import { TransitionTiles } from './TransitionTiles';
import styles from './App.module.css';
import { TransitionLines } from './TransitionLines';

/**
 * TODOS:
 * - add some indication of where in the day you are
 */
export const App = () => {
	/** True for tile mode. False for lines mode. */
	const [tiles, setTiles] = useState(false);

	// expose `window.setNowOffset(n)` where `n` is milliseconds from now
	const nowOffsetRef = useRef(0);
	(window as any).setNowOffset = (o: number) => { nowOffsetRef.current = o; };

	/** The current time, as a timestamp */
	const [now, setNow] = useState(new Date().getTime() + nowOffsetRef.current);
	// console.log(new Date(now));

	const [times, setTimes] = useState<ITimes | undefined>();

	// fetch times on load, and then every hour;
	// update now every second;
	// only do this when the page is visible
	useEffect(
		() => {
			let nowInterval = 0;
			let timesInterval = 0;

			const startIntervals = () => {
				const nowF = () => { setNow(new Date().getTime() + nowOffsetRef.current!); };
				nowF();
				nowInterval = setInterval(nowF, 1000) as unknown as number;

				const timesF = () => { fetchTimes().then(setTimes); };
				timesF();
				timesInterval = setInterval(timesF, 60 * 60 * 1000) as unknown as number;
			};

			const vchange = () => {
				if (document.visibilityState === 'visible')
					startIntervals();
				else {
					clearInterval(nowInterval);
					clearInterval(timesInterval);
				}
			};
			document.addEventListener('visibilitychange', vchange);

			startIntervals();

			return () => {
				if (nowInterval > 0)
					clearInterval(nowInterval);
				if (timesInterval > 0)
					clearInterval(timesInterval);
				document.removeEventListener('visibilitychange', vchange);
			};
		},
		[]
	);

	useEffect(
		() => {
			console.log('Run setNowOffset(4.9 * 60 * 60 * 1000) to jump forward 4.9 hours.')
		},
		[]
	);

	return (
		<div className={c(styles.root, !times && styles.root__loading)}>
			{(() => {
				if (!times)
					return (<>
						Locating your solar data&hellip;
					</>);

				return (
					<div className={styles.transitionWrapper}>
						{tiles
							? (
								<TransitionTiles
									dayAmount={computeDaylightAmount(now, times)}
									twilightDuration={(() => {
										const sunrise = new Date(times.sunrise).getTime();
										const sunset = new Date(times.sunset).getTime();

										// morning twilight
										if (now <= sunrise + 1 * 60 * 1000) {
											const night = new Date(times.astronomical_twilight_begin).getTime();
											return (sunrise - night) / 1000;
										}

										// evening twilight
										else {
											const night = new Date(times.astronomical_twilight_end).getTime();
											return (night - sunset) / 1000;
										}
									})()}
								/>
							)
							: (
								<TransitionLines
									dayAmount={computeDaylightAmount(now, times)}
								/>
							)}

						<button
							className={styles.toggle}
							onClick={() => { setTiles(t => !t); }}
							title="Toggle lines vs. tiles"
						>
							{tiles ? '⇆' : '⇄'}
						</button>
					</div>
				)
			})()}
		</div>
	);
}

