import _ from 'lodash';

/**
 * The data returned from `api.sunrise-sunset.org`
 */
export interface ITimes {
	sunrise: string;
	sunset: string;
	solar_noon: string;
	day_length: number;
	civil_twilight_begin: string;
	civil_twilight_end: string;
	nautical_twilight_begin: string;
	nautical_twilight_end: string;
	astronomical_twilight_begin: string;
	astronomical_twilight_end: string;
}

/** Fetches sunrise/sunset times from `api.sunrise-sunset.org` */
export async function fetchTimes() {
	let coords: GeolocationCoordinates | undefined;
	try {
		coords = await new Promise((res, rej) => {
			// throw new Error('ss')
			navigator.geolocation.getCurrentPosition(e => res(e.coords), rej);
		});
	} catch (e) {
		console.log('fetchTimes error', e);
	}
	const lat = coords?.latitude ?? 40.7796666;
	const lng = coords?.longitude ?? -73.9839166;

	// we need to pass today's date explicitly because the API probably assumes GMT date 🤦‍♂️
	const todaysDate = [
		new Date().getFullYear(),
		_.padStart((new Date().getMonth() + 1).toString(), 2, '0'),
		_.padStart(new Date().getDate().toString(), 2, '0')
	].join('-');
	const response: { results: ITimes } = await fetch(
		`https://api.sunrise-sunset.org/json?lat=${lat}&lng=${lng}&formatted=0&date=${todaysDate}`
	).then(r => r.json());

	return response.results;
}

/**
 * 
 * @param now `new Date().getTime()`
 * @param times as returned my `fetchTimes`
 * @returns [0,1]
 */
export function computeDaylightAmount(now: number, times: ITimes) {
	const {
		astronomical_twilight_begin,
		astronomical_twilight_end,
		sunrise,
		sunset
	} = _.mapValues(times, v => new Date(v).getTime());

	// night
	if (now <= astronomical_twilight_begin || now >= astronomical_twilight_end)
		return 0;

	// day
	if (now >= sunrise && now <= sunset)
		return 1;

	// morning twilight
	if (now < sunrise) {
		const fadeDuration = sunrise - astronomical_twilight_begin;
		return 1 - (sunrise - now) / fadeDuration;
	}

	// evening twilight
	if (now > sunset) {
		const fadeDuration = astronomical_twilight_end - sunset;
		return 1 - (now - sunset) / fadeDuration;
	}

	return 0;
}

const RANDOM_SEED_INIT = 1234;
let seed = RANDOM_SEED_INIT;

/** Returns a "random" number, deterministically from `seed`. */
export function random() {
	var x = Math.sin(seed++) * 10000;
	return x - Math.floor(x);
}
export function resetRandomSeed() {
	seed = RANDOM_SEED_INIT;
}

/**
 * Returns the rows/cols to make each tile as square as possible.
 * @returns [rows, cols]
 */
export function squarify() {
	const width = window.innerWidth;
	const height = window.innerHeight;

	/** [rows, cols, aspect] */
	const dimsAndAspect: [number, number, number][] = [];
	for (let rows = 10; rows <= 50; rows++) {
		const rowHeight = height / rows;
		const cols = Math.round(width / rowHeight);
		const colWidth = width / cols;
		dimsAndAspect.push([rows, cols, colWidth / rowHeight]);
	}
	const sorted = _.sortBy(dimsAndAspect, n => n[2]);
	const best = sorted.find(n => n[2] >= 1)!;
	return best.slice(0, 2)
}