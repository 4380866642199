import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import dayUrl from './day.png';
import { random, resetRandomSeed } from './lib';
import nightUrl from './night.png';
import styles from './TransitionLines.module.css';

interface IProps {
	/** [0,1] */
	dayAmount: number;
}

/**
 * Renders the day image on top of night image.
 * Day lines appear/disappear as twilight progresses.
 */
export const TransitionLines: React.FC<IProps> = ({ dayAmount }) => {
	const rootRef = useRef<HTMLDivElement>(null);
	const canvasRef = useRef<HTMLCanvasElement>(null);

	/**
	 * The day image, loaded via JS for the canvas
	 */
	const dayImageRef = useRef(new Image());

	/**
	 * Whether the day image has been loaded
	 */
	const [dayImageLoaded, setDayImageLoaded] = useState(false);

	// load the day image whenever `dayUrl` changes
	useEffect(
		() => {
			setDayImageLoaded(false);
			dayImageRef.current.onload = () => {
				setDayImageLoaded(true);
			};
			dayImageRef.current.src = dayUrl;
		},
		[]
	);

	useEffect(
		() => {
			if (!dayImageLoaded)
				return;

			const renderCanvas = () => {
				resetRandomSeed();

				const canvas = canvasRef.current!;
				const ctx = canvas.getContext('2d')!;

				// sync the inner canvas render dimensions with the window size
				canvas.width = rootRef.current!.clientWidth;
				canvas.height = rootRef.current!.clientHeight;

				// clear everything
				ctx.clearRect(0, 0, canvas.width, canvas.height);

				// compute the scale and offset to achieve `object-fit: cover`, like the night image (via CSS)
				const fg = dayImageRef.current;
				let scale = 1;
				let xOffset = 0;
				let yOffset = 0;

				// image is "wider" than the screen
				if (fg.naturalWidth / fg.naturalHeight > canvas.width / canvas.height) {
					scale = canvas.height / fg.naturalHeight;
					xOffset = (canvas.width - fg.naturalWidth * scale) / 2;
				}
				// image is "narrower" than the screen
				else {
					scale = canvas.width / fg.naturalWidth;
					yOffset = (canvas.height - fg.naturalHeight * scale) / 2;
				}

				// draw the image
				ctx.drawImage(fg, xOffset, yOffset, fg.naturalWidth * scale, fg.naturalHeight * scale);

				// remove `dayAmount` percentage of the image rows randomly, sans collisions
				const rows = _.range(0, canvas.height);
				for (let i = 0; i < (1 - dayAmount) * canvas.height; i++) {
					const randomIndex = Math.floor(random() * rows.length);
					const row = rows.splice(randomIndex, 1)[0];
					ctx.clearRect(0, row, canvas.width, 1);
				}
			};
			renderCanvas();

			window.addEventListener('resize', renderCanvas);

			return () => {
				window.removeEventListener('resize', renderCanvas);
			};
		},
		[dayAmount, dayImageLoaded]
	);

	return (
		<div className={styles.root} ref={rootRef}>
			<img
				alt="night"
				className={styles.bgImage}
				src={nightUrl}
			/>
			<canvas
				aria-label="day"
				className={styles.canvas}
				ref={canvasRef}
			/>
		</div>
	);
};